import ListAPI from "./listApi";

export default class {
  constructor($element, object) {
    this.$element = $element;
    this.id = this.$element.attr("id");
    this.tooltipName = `${this.id}_interactive_box`;
    this.object = object;
    this.popUpTemplate = $(
      `<div id="rm_list_${this.id}_template" class="template"></div>`
    );
    this.popUpList = $(`<ul id="rm_list_${this.id}_user_lists"></ul>`);
    this.popUpForm =
      $(`<form method="post" id="rm_list_${this.id}_form" style="display: none;">
                              <input type="text" id="title" name="title"><br>
                              <div class="button_wrapper">
                                <span class="create_list" data-value="Create" id="rm_list_${this.id}_create_btn">
                                </span>
                                <span class="undo_list" data-value="Undo" id="rm_list_${this.id}_undo_btn">
                                </span>
                              </div>
                            </form>`);
    this.popUpAddToBtn = $(
      `<div id="rm_list_${this.id}_add_to" class="popup_add_to"><p>Add to list(s)</p></div>`
    );
    this.popUpCreateNewBtn =
      $(`<div id="rm_list_${this.id}_create_new" class="popup_create_new">
                                    <span class="plus">&plus;</span>
                                    Create new list
                                  </div>`);
    this.popUpResponseMsg = $(
      '<div id="response-message" style="display: none;"></div>'
    );
  }

  popUpListElement(listTitle, uuid, isGameOnList, sharedList) {
    const list = $(`<li data-identifier="${uuid}">
        <input class="rm_list_checkbox" id="input-${uuid}" type="checkbox">
        <label for="input-${uuid}">${listTitle}</label>
      </li>`);

    if (isGameOnList === "true") {
      list.find("input[type=checkbox]").attr("checked", "checked");
    }

    if (sharedList) {
      list.append($('<i class="fa-icons-tooltip fa fa-users"></i>'));
    }

    return list;
  }

  errorHandler(message) {
    this.popUpResponseMsg
      .show()
      .append('<span class="error-icon"></span>')
      .append($(`<span class="error-response">${message}</span>`));

    const that = this;
    setTimeout(() => {
      that.popUpResponseMsg.empty().hide();
      that.popUpForm.find("#title").val("");
    }, 3000);
  }

  getAllLists() {
    const game_uuid = this.object.uuid;

    ListAPI.getAllGameLists(
      game_uuid,
      (response) => {
        this.popUpList.empty();
        response.forEach((list) => {
          this.popUpList.append(
            this.popUpListElement(
              list.title,
              list.uuid,
              list.game_in_list,
              list.shared_list
            )
          );
        });
      },
      (response) => {}
    );
  }

  tooltipContent() {
    this.getAllLists();

    return this.popUpTemplate
      .append(this.popUpAddToBtn)
      .append(this.popUpList)
      .append(this.popUpForm)
      .append(this.popUpCreateNewBtn)
      .append(this.popUpResponseMsg);
  }

  toggleListForm() {
    this.popUpCreateNewBtn.on("click", () => {
      this.popUpCreateNewBtn.hide();
      this.popUpForm.show();
      $(`#${this.id}`).tooltipster("reposition");
    });

    this.popUpForm
      .find("span[data-value=Undo]")
      .unbind()
      .click(() => {
        this.popUpForm.find("#title").val("");
        this.popUpForm.hide();
        this.popUpCreateNewBtn.show();
        $(`#${this.id}`).tooltipster("reposition");
      });
  }

  toggleNotifications(number) {
    if (number > 0) {
      $(".notification-counter-big").show();
    } else {
      $(".notification-counter-big").hide();
    }
  }

  setUpdateNotifications() {
    if (
      $(".game-details-page").length > 0 &&
      $(".game-page-title").length > 0
    ) {
      ListAPI.getListsNotifications(
        $(".game-page-title").attr("data-uuid"),
        (response) => {
          if ($(".notification-counter-big").length) {
            $(".notification-counter-big").empty().text(response);
            this.toggleNotifications(response);
          }
        },
        (response) => {}
      );
    }
  }

  createNewListHandler() {
    if (this.popUpResponseMsg.html().length > 0) {
      this.popUpResponseMsg.empty();
    }

    const newListName = this.popUpForm.find("#title");

    if (newListName.val().length > 0) {
      ListAPI.createList(
        newListName.val(),
        (response) => {
          if (response.hasOwnProperty("error")) {
            this.errorHandler(response.error);
          } else {
            this.popUpList.prepend(
              this.popUpListElement(response.title, response.uuid)
            );
            this.popUpForm.hide();
            newListName.val("");
            this.popUpCreateNewBtn.show();

            const list_uuid = response.uuid;
            const component = this;

            ListAPI.addGameToList(
              this.object.uuid,
              list_uuid,
              (response) => {
                $(`#${this.id}`).tooltipster("reposition");
                if (response.hasOwnProperty("error")) {
                  // this.errorHandler(response.error);
                  consle.log(response.error);
                } else {
                  const checkbox = $(`li[data-identifier="${list_uuid}"]`).find(
                    "input"
                  );
                  $(checkbox).attr("checkbox", "checked");
                  $(checkbox).prop("checked", true);

                  // for game profile
                  component.setUpdateNotifications();
                }
              },
              (response) => {
                this.errorHandler(response.error);
              }
            );

            $(`#${this.id}`).tooltipster("reposition");
          }
        },
        (response) => {
          this.errorHandler(response.error);
        }
      );

      $(`#${this.id}`).tooltipster("reposition");
    }
  }

  createNewList() {
    this.popUpForm
      .find("span[data-value=Create]")
      .unbind()
      .click(() => {
        this.createNewListHandler();
      });

    this.popUpForm.find("#title").keydown((e) => {
      if (e.keyCode == 13) {
        this.createNewListHandler();
      }
    });
  }

  toggleAddGameToList() {
    const component = this;
    const game_uuid = component.object.uuid;

    this.popUpList.on("click", "li", function (e) {
      e.preventDefault();

      const checkbox = $(this).find("input");
      const list_uuid = $(this).attr("data-identifier");

      if (
        $(checkbox).prop("checked") ===
        !!$(e.target).hasClass("rm_list_checkbox")
      ) {
        ListAPI.addGameToList(
          game_uuid,
          list_uuid,
          (response) => {
            $(`#${component.id}`).tooltipster("reposition");
            if (response.hasOwnProperty("error")) {
              component.errorHandler(response.error);
            } else {
              $(checkbox).attr("checkbox", "checked");
              $(checkbox).prop("checked", true);

              // for game profile
              component.setUpdateNotifications();
              if ($("#badge-wrapper").length > 0) {
                GameBadges.setUpdateListsBadges("#list-badges", 1);
              }
            }
          },
          (response) => {
            component.errorHandler(response.error);
          }
        );
        $(`#${component.id}`).tooltipster("reposition");
      } else {
        ListAPI.removeGameFromList(
          game_uuid,
          list_uuid,
          (response) => {
            $(`#${component.id}`).tooltipster("reposition");

            if (response.hasOwnProperty("error")) {
              component.errorHandler(response.error);
            } else {
              $(checkbox).attr("checkbox", "");
              $(checkbox).prop("checked", false);

              // for game profile
              component.setUpdateNotifications();
              if ($("#badge-wrapper").length > 0) {
                GameBadges.setUpdateListsBadges("#list-badges", 1);
              }
            }
          },
          (response) => {
            // component.errorHandler(response.error);
            console.log(response.error);
          }
        );
      }
    });
    $(`#${component.id}`).tooltipster("reposition");
  }

  checkTooltipsterHeight() {
    if ($(".tooltipster-shadow ul").height >= 360) {
      $(".tooltipster-shadow ul").css("overflow-y", "scroll");
    }
  }

  initialize() {
    $(`#${this.id}`).tooltipster({
      content: "Content loading...",
      functionBefore: (instance, helper) => {
        instance.content(this.tooltipContent());
      },
      trigger: "click",
      interactive: true,
      position: "bottom",
      animation: "slide",
      animationDuration: 100,
      theme: "tooltipster-shadow",
      contentAsHTML: true,
      functionReady: () => {
        this.checkTooltipsterHeight();
        this.toggleListForm();
        this.createNewList();
        this.toggleAddGameToList();
      },
    });

    this.setUpdateNotifications();
  }
}

export default class {
  static sendRequest(url, method, data, successCallback, errorCallback) {
    return $.ajax({
      url: url,
      type: method,
      data: data,
      cache: false,
      success: successCallback,
      error: errorCallback,
      dataType: "json",
    });
  }

  static getResourcesWithRole(list_uuid, success, error) {
    const listData = $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/fetch_org_resources_with_role",
      "GET",
      listData,
      success,
      error
    );
  }

  static addUpdateRole(
    resource_type,
    resource_id,
    old_role,
    new_role,
    list_uuid,
    success,
    error
  ) {
    const resource = resource_type.slice(0, -1);
    const resourceData =
      $.param({ resource_type: resource }) +
      "&" +
      $.param({ resource_id: resource_id }) +
      "&" +
      $.param({ old_role: old_role }) +
      "&" +
      $.param({ new_role: new_role }) +
      "&" +
      $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/add_update_role",
      "POST",
      resourceData,
      success,
      error
    );
  }

  static removeRole(
    resource_type,
    resource_id,
    role,
    list_uuid,
    success,
    error
  ) {
    const resourceData =
      $.param({ resource_type: resource_type }) +
      "&" +
      $.param({ resource_id: resource_id }) +
      "&" +
      $.param({ role: role }) +
      "&" +
      $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/remove_role",
      "DELETE",
      resourceData,
      success,
      error
    );
  }

  static mostRecentChanges(success, error) {
    this.sendRequest(
      "/api/user_lists/fetch_recent_changes",
      "GET",
      "",
      success,
      error
    );
  }

  static createList(title, success, error) {
    const listData =
      $.param({ icon: "fa fa-align-justify, #7fd054" }) +
      "&" +
      $.param({ title: title });
    return this.sendRequest(
      "/api/user_lists/create_list",
      "POST",
      listData,
      success,
      error
    );
  }

  static updateList(list_uuid, list_params, success, error) {
    const listData = list_params + "&" + $.param({ list_uuid: list_uuid });
    return this.sendRequest(
      "/api/user_lists/update_list",
      "PATCH",
      listData,
      success,
      error
    );
  }

  static toggleListBadge(list_uuid, badge, success, error) {
    const listData =
      $.param({ list_uuid: list_uuid }) + "&" + $.param({ badge: badge });
    this.sendRequest(
      "/api/user_lists/toggle_list_badge",
      "PATCH",
      listData,
      success,
      error
    );
  }

  static deleteList(list_uuid, success, error) {
    const listData = $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/delete_list",
      "DELETE",
      listData,
      success,
      error
    );
  }

  static getAllGameLists(game_uuid, success, error) {
    const gameData = $.param({ game_uuid: game_uuid });
    this.sendRequest(
      "/api/user_lists/fetch_all_game_lists.json",
      "GET",
      gameData,
      success,
      error
    );
  }

  static getListsNotifications(game_uuid, success, error) {
    const gameData = $.param({ game_uuid: game_uuid });
    this.sendRequest(
      "/api/user_lists/fetch_lists_notifications.json",
      "GET",
      gameData,
      success,
      error
    );
  }

  static getGameBadges(game_uuid, success, error) {
    const gameData = $.param({ game_uuid: game_uuid });
    this.sendRequest(
      "/api/user_lists/fetch_game_badges.json",
      "GET",
      gameData,
      success,
      error
    );
  }

  static addGameToList(game_uuid, list_uuid, success, error) {
    const gameData =
      $.param({ game_uuid: game_uuid }) +
      "&" +
      $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/add_game_to_list",
      "POST",
      gameData,
      success,
      error
    );
  }

  static removeGameFromList(game_uuid, list_uuid, success, error) {
    const gameData =
      $.param({ game_uuid: game_uuid }) +
      "&" +
      $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/remove_game_from_list",
      "DELETE",
      gameData,
      success,
      error
    );
  }

  static getUserListItems(list_uuid, success, error) {
    const listData = $.param({ list_uuid: list_uuid });
    this.sendRequest(
      "/api/user_lists/fetch_user_list_items.json",
      "GET",
      listData,
      success,
      error
    );
  }

  static updateUserListItem(
    user_list_item_params,
    user_list_item_id,
    success,
    error
  ) {
    let listData =
      this.serialize(user_list_item_params) +
      "&" +
      $.param({ id: user_list_item_id });
    this.sendRequest(
      "/api/user_lists/update_user_list_item.json",
      "PATCH",
      listData,
      success,
      error
    );
  }

  static serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  static errorHandler(errorText) {
    if ($("#lists_error_messages").children().length <= 0) {
      $("#lists_error_messages")
        .addClass("lists_errors")
        .append('<span class="error-icon"></span>')
        .append(
          '<span class="error-text">Oops.. an error occured. ' +
            errorText +
            "</span>"
        );
    }

    setTimeout(function () {
      $("#lists_error_messages").removeClass("lists_errors").empty();
    }, 3000);
  }
}

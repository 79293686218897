$(() => {
  $("#rm-autocomplete-games").autocomplete({
    minChars: 2,
    serviceUrl: "/api/games/filter_games",
    autoSelectFirst: true,
    disableMouseOver: false,
    identifier: "autocomplete-games",
    zIndex: 9,
    deferRequestBy: 300,
    ajaxSettings: {
      headers: {
        Accept: "application/vnd.api+json",
      },
    },
    onSelect(value, data) {
      $(".search-field").val("");

      const uuid = value.replace(data, "").split(" - ")[1];
      document.location = `/game/${uuid}`;
    },
  });
});

$(window).on("load", () => {
  $("#rm-autocomplete-games").on("keypress", (e) => {
    if (e.key === 13 || e.key.toLowerCase() === "enter") {
      e.preventDefault();
    }
  });
});

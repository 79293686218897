$(window).on("load", () => {
  const checkSide = function (tooltip) {
    if (tooltip.hasClass("tooltipster-left")) {
      return "left";
    }
    return "right";
  };

  return $(
    ".tooltipster-information-icon, .tooltipster-alert-icon, .tooltipster-information-icon-game-page"
  ).each(function (_, element) {
    const $tooltip = $(element);
    const $content = $($tooltip.data("tooltip-content"));

    return $tooltip.tooltipster({
      content: $content,
      contentAsHTML: true,
      contentCloning: true,
      theme: ["tooltipster-gray"],
      side: checkSide($tooltip),
    });
  });
});

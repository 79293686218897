$(document).on("click", ".tabs-trigger--glossary", function () {
  $("#rm-autocomplete-glossary-nodes").autocomplete({
    serviceUrl: "/help/filter_glossaries",
    ajaxSettings: {
      headers: {
        Accept: "application/vnd.api+json",
      },
    },
    onSelect: function (value, _) {
      $.ajax({
        method: "GET",
        url: "/glossary/" + value + ".js",
        accepts: "text/javascript",
        success: function (script) {
          eval(script);
        },
      });

      $(".search-field").val("");
    },
    disableMouseOver: false,
    deferRequestBy: 300,
    identifier: "autocomplete-glossary-nodes",
  });
});

$(function () {
  $(".site_feature input").on("click", function (e) {
    e.preventDefault();

    var input = $(this);
    var params =
      $.param({ organization_id: input.data("organization") }) +
      "&" +
      $.param({ site_feature_id: input.data("site-feature") });

    if ($(this).attr("checked") !== undefined) {
      //Delete OrganizationSiteFeature
      $.ajax({
        type: "DELETE",
        dataType: "json",
        cache: false,
        url:
          "/content_admin/organizations/" +
          input.data("organization") +
          "/destroy_organization_site_feature",
        data: params,
        success: function (response) {
          input.removeAttr("checked");
          input.prop("checked", false);
        },
        error: function (response) {
          console.log(response);
        },
      });
    } else {
      //Create OrganizationSiteFeature
      $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        url:
          "/content_admin/organizations/" +
          input.data("organization") +
          "/create_organization_site_feature",
        data: params,
        remote: true,
        success: function (response) {
          input.attr("checked", "checked");
          input.prop("checked", true);
        },
        error: function (response) {
          console.log(response);
        },
      });
    }
  });
});

import MostRecent from "./userLists/mostRecent";
class SidebarToggle {
  static slideInOut() {
    let clicked;
    clicked = true;
    $(".user-menu-button-container").on("click", () => {
      if (clicked) {
        clicked = false;
        $(".user-container").animate({ right: "0em" });
        MostRecent.setUpdateLists($(".blocklist-container.my_lists"));
      } else {
        clicked = true;
        $(".user-container").animate({ right: "-30em" });
      }
    });
  }
}

$(window).on("load", () => {
  window.SidebarToggle = SidebarToggle;
  SidebarToggle.slideInOut();
});

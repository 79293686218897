// -----------------------------------------------------------
// DOCUMENT ON READY
// -----------------------------------------------------------
var $window = $(window);

// -----------------------------------------------------------
// WINDOW RESIZE
// -----------------------------------------------------------
var SCREENSIZE = 0;
var WIDESCREEN = false;
function windowResize() {
  if (window.getComputedStyle != null) {
    SCREENSIZE = window
      .getComputedStyle(document.body, ":after")
      .getPropertyValue("content");
    SCREENSIZE = parseInt(SCREENSIZE.replace(/["']{1}/gi, ""));
    if (isNaN(SCREENSIZE)) SCREENSIZE = 0;
  }
  WIDESCREEN = SCREENSIZE > 30;
}

function destroyTooltip(tooltipsterSelector) {
  $(tooltipsterSelector).each(function (t, n) {
    $(n).tooltipster("destroy");
  });
}

function restoreTooltip(tooltipsterSelector, e) {
  e = function (e) {
    return e.hasClass("tooltipster-left") ? "left" : "right";
  };

  $(tooltipsterSelector).each(function (t, n) {
    var i, r;
    return (
      (r = $(n)),
      (i = $(r.data("tooltip-content"))),
      r.tooltipster({
        content: i,
        contentAsHTML: true,
        contentCloning: true,
        theme: ["tooltipster-gray"],
        side: e(r),
      })
    );
  });
}

$(function () {
  $window.on("resize", windowResize);
  windowResize();

  /////////////////
  // CARDS MAGIC //
  /////////////////

  $(".game-card").each(function (i, obj) {
    var self = this;
    $(self).attr("data-card", "-" + i);

    var cardClass = $(self).attr("class").split(" ")[0].split("-");
    var cardTitle = cardClass[1] + "-" + cardClass[2];
    $(self).attr("data-title", cardTitle);
  });

  $(".game-card-content").each(function (i, obj) {
    var self = this;
    $(self).attr("id", "data-card-" + i);
  });

  $(".filters-container").hide();

  $("body").on("click", ".game-card", function (e) {
    window.dispatchEvent(new Event("resize"));

    var self = this;
    var row = $(self).parent().attr("class").split(" ")[0];
    var card = $(self).attr("data-card");
    var dataChartElement = {};
    var dataChart = {};
    var filters = $(".filters-container");
    var clickedRow = "." + row;
    var clickedCard = "#data-card" + card;
    var clickedCardTitle = $(this).attr("data-title");

    $(".filters-container").show();

    $(".sheet div").each(function () {
      dataChartElement = $(clickedCard).find("[data-chart]");
      dataChart = dataChartElement.attr("data-chart");
      dataChart.debouncedResize;
    });

    // Patch because switching cards did not remove the indicator. Closing seems not to work either.
    $(".game-card.selectedItem").removeClass("selectedItem");

    // Attach indicator on active card
    $(self).addClass("selectedItem");

    if (clickedRow === ".row-one") {
      // click on first row first

      destroyTooltip(".row-two-outer .tooltipster-information-icon");

      $(".row-two-outer .row-two")
        .detach()
        .clone(true, true)
        .appendTo(".floating-card-container")
        .slideDown("slow", function () {});

      if (DashboardHelpers.siteFeatureUnlocked("conversions")) {
        if (render_sankey_diagram_conversions) {
          render_sankey_diagram_conversions.update();
        }
      }

      if ($(".row-two-outer ul").length > 1) {
        $("row-two")[0].remove();
      }

      restoreTooltip(
        ".floating-card-container .tooltipster-information-icon",
        e
      );

      $(".game-card-content").each(function () {
        dataChartElement = $(clickedCard).find("[data-chart]");
        dataChart = dataChartElement.attr("data-chart");
        dataChart.debouncedResize;
        $(this).fadeOut(200, function () {
          $(this).addClass("hidden");
        });
      });

      $(document).find(clickedCard).toggleClass("hidden").fadeIn(700);
      window.dispatchEvent(new Event("resize"));

      if (
        clickedCardTitle === "internal-overview" ||
        clickedCardTitle === "average-bet"
      ) {
        if (ReelMetrics.supplier_edition) $(".filters-container").hide();
      }

      // $('html, body').animate({ scrollTop: $('.filters-container').offset().top - 75 }, 700)
    } else if ($(self).parent().parent().attr("class") === "row-two-outer") {
      // otherwise this is if the first click on the second row

      destroyTooltip(".row-two-outer .tooltipster-information-icon");

      $(".row-two").detach().clone(true, true).appendTo(".row-two-outer");

      if (DashboardHelpers.siteFeatureUnlocked("conversions")) {
        if (render_sankey_diagram_conversions) {
          render_sankey_diagram_conversions.update();
        }
      }

      restoreTooltip(".row-two-outer .tooltipster-information-icon", e);

      $(".game-card-content").each(function () {
        $(this).fadeOut(200, function () {
          $(this).addClass("hidden");
        });
        dataChartElement = $(clickedCard).find("[data-chart]");
        dataChart = dataChartElement.attr("data-chart");
        dataChart.debouncedResize;
      });
      $(document).find(clickedCard).toggleClass("hidden").fadeIn(700);
      window.dispatchEvent(new Event("resize"));

      if (
        clickedCardTitle === "internal-overview" ||
        clickedCardTitle === "average-bet"
      ) {
        if (ReelMetrics.supplier_edition) $(".filters-container").hide();
      }

      // $('html, body').animate({ scrollTop: $('.filters-container').offset().top - 75 }, 700)
    } else if (
      $(self).parent().parent().attr("class") === "floating-card-container"
    ) {
      // if the clicked card exists in the invisible third row because first a first row card was clicked

      destroyTooltip(".floating-card-container .tooltipster-information-icon");

      $(".row-two").detach().clone(true, true).appendTo(".row-two-outer");

      if (DashboardHelpers.siteFeatureUnlocked("conversions")) {
        if (render_sankey_diagram_conversions) {
          render_sankey_diagram_conversions.update();
        }
      }

      restoreTooltip(".row-two-outer .tooltipster-information-icon", e);

      $(".game-card-content").each(function () {
        $(this).fadeOut(200, function () {
          $(this).addClass("hidden");
        });
      });
      $(document).find(clickedCard).toggleClass("hidden").fadeIn(700);
      window.dispatchEvent(new Event("resize"));

      if (
        clickedCardTitle === "internal-overview" ||
        clickedCardTitle === "average-bet"
      ) {
        if (ReelMetrics.supplier_edition) $(".filters-container").hide();
      }

      // $('html, body').animate({ scrollTop: $('.filters-container').offset().top - 75 }, 700);
    } else {
      // uh oh
    }
  });

  var closeContainerScope = {};
  var nextContainerClick = {};
  var nextContainerScope = {};
  var prevContainerClick = {};
  var prevContainerScope = {};
  var cardExistenceCheck = {};
  var clickThisCard = {};

  $(".card-nav-close").on("click", function () {
    closeContainerScope = $(this).parent().attr("id");
    closeContainerScope = closeContainerScope.substr(-2);
    $("li")
      .find("[data-card='" + closeContainerScope + "']")
      .removeClass("selectedItem");
    $(this).parent().toggleClass("hidden");
    $(this).parent().css({ display: "none" });
    window.dispatchEvent(new Event("resize"));
    $(".filters-container").hide();
  });

  $(document).on("click", ".card-nav-next", function (event) {
    event.preventDefault();
    clickThisCard = {};
    closeContainerScope = $(this).parent().attr("id");
    closeContainerScope = closeContainerScope.substr(-2);
    nextContainerScope = $(this).parent().attr("id");
    nextContainerScope = nextContainerScope.substr(-1);
    nextContainerClick = parseInt(nextContainerScope);
    nextContainerClick = nextContainerClick + 1;
    nextContainerClick = "-" + nextContainerClick;

    cardExistenceCheck = $(document).find(
      "[data-card='" + nextContainerClick + "']"
    );

    if (cardExistenceCheck.length) {
      $("li")
        .find("[data-card='" + closeContainerScope + "']")
        .removeClass("selectedItem");
      $(this).parent().toggleClass("hidden");
      clickThisCard = $(document).find(
        "[data-card='" + nextContainerClick + "']"
      );
      $(clickThisCard).click();
    } else {
    }
  });

  $(document).on("click", ".card-nav-prev", function () {
    clickThisCard = {};
    closeContainerScope = $(this).parent().attr("id");
    closeContainerScope = closeContainerScope.substr(-2);
    prevContainerScope = $(this).parent().attr("id");
    prevContainerScope = prevContainerScope.substr(-1);
    prevContainerClick = parseInt(prevContainerScope);
    prevContainerClick = prevContainerClick - 1;
    prevContainerClick = "-" + prevContainerClick;
    cardExistenceCheck = $(document).find(
      "[data-card='" + prevContainerClick + "']"
    );
    if (cardExistenceCheck.length) {
      $("li")
        .find("[data-card='" + closeContainerScope + "']")
        .removeClass("selectedItem");
      $(this).parent().toggleClass("hidden");
      clickThisCard = $(document).find(
        "[data-card='" + prevContainerClick + "']"
      );
      $(clickThisCard).click();
    } else {
    }
  });

  new Help();

  // Togglers
  $(".js-toggler").toggler();

  $(".search-container--mobile").toggler(
    ".search-container--mobile .search-button"
  );

  $("#user").toggler(".button--user-menu");

  // Tabs
  $(".tabs").tabs();

  $(".js-notification").on("click", function () {
    $(this).parent().find(".notification").addClass("active");
  });
  $(".js-notification-close").on("click", function () {
    $(this).parents().find(".notification").removeClass("active");
  });
  setTimeout(function () {
    $(".notification").removeClass("active");
  }, 1700);

  $(".open-popup-link").magnificPopup({
    type: "inline",

    fixedContentPos: false,
    fixedBgPos: true,

    overflowY: "auto",

    closeBtnInside: true,
    preloader: false,

    midClick: true,
    removalDelay: 300,
    mainClass: "my-mfp-zoom-in",
  });

  //gallery
  $(".popup-gallery").magnificPopup({
    delegate: "a",
    type: "image",
    tLoading: "Loading image #%curr%...",
    mainClass: "mfp-img-mobile",
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function (item) {
        return item.el.attr("title") + "<small></small>";
      },
    },
  });

  //popup video
  $(".popup-vimeo").magnificPopup({
    disableOn: 700,
    type: "iframe",
    mainClass: "mfp-fade",
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false,
  });

  //ripple effect
  var config = {
    duration: 300,
    delay: 200,
  };
  Waves.attach(".button", ["waves-button", "waves-light", "waves-ripple"]);
  Waves.init(config);
});

// -----------------------------------------------------------
// TOGGLER
// -----------------------------------------------------------
$.fn.toggler = function (trigger) {
  return $(this).each(
    function (index, item) {
      item = $(item);
      if (!item.data("toggler")) {
        item.data("toggler", new Toggler(item, trigger));
      }
    }.bind(this)
  );
};

var Toggler = function (element, trigger) {
  this.element = element;
  this.trigger = trigger ? $(trigger) : element;
  this.trigger.on("click", this.toggle.bind(this));
};

Toggler.prototype.toggle = function (e) {
  e.preventDefault();
  this.element.toggleClass("active");
};

// -----------------------------------------------------------
// TABS
// -----------------------------------------------------------
$.fn.tabs = function () {
  return $(this).each(
    function (index, item) {
      item = $(item);
      if (!item.data("tabs")) {
        item.data("tabs", new Tabs(item));
      }
    }.bind(this)
  );
};

var Tabs = function (element) {
  this.element = element;

  this.triggers = this.element.find(".tabs-trigger");
  this.container = this.element.find(".tabs-panes");
  this.panes = this.container.find(".tabs-pane");

  if (this.triggers.length !== 0) {
    if (this.triggers.first().attr("href").substr(0, 1) == "#") {
      this.triggers.on("click", this.change.bind(this));
    } else {
      this.triggers.on("click", this.load.bind(this));
    }
  }
};

Tabs.prototype.change = function (e) {
  e.preventDefault();

  var trigger = $(e.currentTarget);
  this.triggers.removeClass("active");
  trigger.addClass("active");

  this.panes.removeClass("active");
  this.panes
    .eq(trigger.closest(".tabs-triggers-item").index())
    .addClass("active");
};

Tabs.prototype.load = function (e) {
  e.preventDefault();
  var trigger = $(e.currentTarget);

  if (this.loader) {
    this.loader.abort();
  }

  this.loader = $.get(trigger.attr("href"), this.loaded.bind(this));
};

Tabs.prototype.loaded = function (data) {
  this.container.html(data);
};

// -----------------------------------------------------------
// HELP
// -----------------------------------------------------------
var Help = function () {
  this.element = $(".help-container");
  if (!this.element.length) return;
  this.parent = this.element.parent();
  this.content = this.element.find(".help-content");
  this.content.on(
    "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
    this.resize.bind(this)
  );
  this.trigger = this.element.find(".js-help-trigger");
  this.trigger
    .add(this.element.find(".js-help-close"))
    .on("click", this.toggle.bind(this));

  $window.on("scroll", this.scroll.bind(this));
  $window.on("resize", this.resize.bind(this));
  this.resize();
};

Help.prototype.toggle = function (e) {
  e.preventDefault();
  this.element.css("cursor", "wait");

  if (!this.content.children().length) {
    var that = this;
    $.ajax({
      type: "GET",
      dataType: "html",
      url: "/help/load_help_content",
      success: function (data) {
        that.content.html(data);
        that.element.css("cursor", "");
        $(".tabs").tabs();
        that.autocomplete();
        that.videosSRC();
      },
      error: function () {},
    });
  }

  this.content.toggleClass("active");
  this.trigger.toggleClass("active", this.content.hasClass("active"));

  if (this.content.hasClass("active")) {
    $("body").addClass("no-scroll");
  } else {
    $("body").removeClass("no-scroll");
  }
};

Help.prototype.autocomplete = function () {
  $("#rm-autocomplete-glossary").autocomplete({
    serviceUrl: "/help/filter_glossaries",
    paramName: "term",
    ajaxSettings: {
      headers: {
        Accept: "application/vnd.api+json",
      },
    },
    transformResult: function (response) {
      return {
        suggestions: JSON.parse(response),
      };
    },
    onSelect: function (suggestion) {
      $.ajax({
        method: "GET",
        url: "/glossary/" + suggestion.value + ".js",
        accepts: "text/javascript",
        success: function (script) {
          return eval(script);
        },
      });

      return $(".search-field").val("");
    },
  });
};

Help.prototype.videosSRC = function () {
  if (typeof ReelMetrics !== "undefined" && ReelMetrics !== null) {
    return (function () {
      var result = [];
      ReelMetrics.videos.forEach(function (video) {
        if (document.getElementById(video[0])) {
          result.push(
            document.getElementById(video[0]).setAttribute("src", video[1])
          );
        } else {
          result.push(undefined);
        }
      });
      return result;
    })();
  }
};

Help.prototype.resize = function () {
  this.element.execute(this, 100, function () {
    this.top = Math.floor(this.parent.offset().top);
    this.height = this.element.outerHeight();
    this.scroll();
    if (this.active) {
      this.parent.css("height", this.height + "px");
    }
  });
};

Help.prototype.scroll = function () {
  var top = $window.scrollTop();
  if (WIDESCREEN && top > this.top) {
    if (!this.active) {
      this.active = true;
      this.element.addClass("active");
      this.parent.css("height", this.height + "px");
    }
  } else if (this.active) {
    this.active = false;
    this.element.removeClass("active");
    this.parent.css("height", "");
  }
};

function passwordMatchCheck(password, confirm_password) {
  if (password.value != confirm_password.value) {
    confirm_password.setCustomValidity("Passwords Don't Match");
  } else {
    confirm_password.setCustomValidity("");
  }
}

function passwordValidCheck(password) {
  regexResult = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S+]{8,}$/.test(
    password.value
  );
  const email = document.getElementById("access_request_email").value;
  const email_name = email.substring(0, email.lastIndexOf("@"));

  if (regexResult == false || password.value.indexOf(email_name) !== -1) {
    password.setCustomValidity("Password is not valid");
  } else {
    password.setCustomValidity("");
  }
}

function passwordCheck() {
  const password = document.getElementById("access_request_password");
  const confirm_password = document.getElementById(
    "access_request_password_confirmation"
  );

  if (password) {
    password.addEventListener(
      "change",
      () => passwordMatchCheck(password, confirm_password),
      false
    );

    password.addEventListener(
      "change",
      () => passwordValidCheck(password),
      false
    );

    password.addEventListener(
      "keyup",
      () => passwordMatchCheck(password, confirm_password),
      false
    );
  }

  if (confirm_password) {
    confirm_password.addEventListener(
      "keyup",
      () => passwordMatchCheck(password, confirm_password),
      false
    );
  }
}

window.addEventListener("load", passwordCheck, false);

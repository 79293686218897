var ReelMetrics = ReelMetrics || {};
ReelMetrics.passwordHelpers = {
  successHandler: function (response) {
    if (
      response.hasOwnProperty("errorId") &&
      response.hasOwnProperty("errorCauses")
    ) {
      ReelMetrics.passwordHelpers.fillResponseMessage(
        "error-response",
        response.errorCauses[0].errorSummary,
        "error-icon"
      );
    } else {
      if ($("#change_password_wrapper").length > 0) {
        ReelMetrics.passwordHelpers.fillResponseMessage(
          "success-response",
          "Your password has been successfully changed.",
          "success-icon"
        );
        $("#change_password_wrapper").append(
          $("#request-response-message").clone()
        );
      }
      if ($("#validate_token_wrapper").length > 0) {
        $("#success_message").show();
      }
      $("#form_wrapper").css("display", "none");
    }
  },
  errorHandler: function (error) {
    var errorCode =
      "Oops.. a technical error occured. " +
      error.status +
      ": " +
      error.statusText;
    ReelMetrics.passwordHelpers.fillResponseMessage(
      "error-response",
      errorCode,
      "error-icon"
    );
  },
  fillResponseMessage: function (messageClass, message, iconClass) {
    $("#request-response-message")
      .addClass(messageClass)
      .text(message)
      .prepend('<span class="' + iconClass + '"></span>');
  },
  errorBlankPasswords: function () {
    ReelMetrics.passwordHelpers.fillResponseMessage(
      "error-response",
      "Passwords cannot be blank.",
      "error-icon"
    );
  },
  errorNotMacthingPasswords: function () {
    ReelMetrics.passwordHelpers.fillResponseMessage(
      "error-response",
      "Passwords must match.",
      "error-icon"
    );
  },
};

$(function () {
  $("#save-changed-password").on("click", function () {
    var oldPassword = $("#old_password input[type=password]").val(),
      newPassword = $("#new_password input[type=password]").val(),
      confirmPassword = $("#confirm_password input[type=password]").val();

    if (
      newPassword === confirmPassword &&
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      $("#request-response-message").removeAttr("class").text("");
      $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        url: "/tools/change_password",
        data: {
          old_password: $("#old_password").find("input").val(),
          new_password: $("#new_password").find("input").val(),
        },
        success: ReelMetrics.passwordHelpers.successHandler,
        error: ReelMetrics.passwordHelpers.errorHandler,
      });
    } else if (
      oldPassword.length < 1 ||
      newPassword.length < 1 ||
      confirmPassword.length < 1
    ) {
      ReelMetrics.passwordHelpers.errorBlankPasswords();
    } else {
      ReelMetrics.passwordHelpers.errorNotMacthingPasswords();
    }
  });
  $("#save-new-password").on("click", function () {
    (newPassword = $("#new_password").val()),
      (confirmPassword = $("#confirm_password").val());
    if (
      newPassword === confirmPassword &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      $("#request-response-message").removeAttr("class").text("");
      $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        url: "/validate_token/change_password",
        data: $("#validate_token_form")
          .find("input[name!=confirm_password]")
          .serialize(),
        success: ReelMetrics.passwordHelpers.successHandler,
        error: ReelMetrics.passwordHelpers.errorHandler,
      });
    } else if (newPassword.length < 1 || confirmPassword.length < 1) {
      ReelMetrics.passwordHelpers.errorBlankPasswords();
    } else {
      ReelMetrics.passwordHelpers.errorNotMacthingPasswords();
    }
  });
});

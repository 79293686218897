import ListAPI from "./listApi";

export default class {
  static listElement(change) {
    return $(`<li class="my_lists blocklist-item">
                  <a class="blocklist-link" href="/tools/user_list_items">${change}</a>
                </li>`);
  }

  static updateDOM(target, dataSource) {
    if ($(target).find("ul").length > 0) {
      $(target).find("ul").empty();
    } else {
      $(target).append($('<ul class="blocklist list"></ul>'));
    }

    dataSource.forEach((elem) => {
      $(target).find("ul").append(this.listElement(elem));
    });
  }

  static setUpdateLists(target) {
    ListAPI.mostRecentChanges(
      (response) => {
        if (response.hasOwnProperty("error")) {
          console.log(error);
        } else {
          this.updateDOM(target, response);
        }
      },
      (response) => {
        console.log(response);
      }
    );
  }
}

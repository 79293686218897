import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "bootstrap";
import "tooltipster";
import "jquery.autocomplete";
import "magnific-popup";
import "node-waves";

import OktaTokenManager from "../services/oktaTokenManager";

window.Rails = Rails;
Rails.start();

ActiveStorage.start();

window.OktaTokenManager = OktaTokenManager;
OktaTokenManager.init();

// JS imports of needed
import "../legacy/main";

// CSS
import "bootstrap/scss/bootstrap";
import "@fortawesome/fontawesome-free/css/all";
// import "@fortawesome/fontawesome-free/scss/fontawesome";
// import "@fortawesome/fontawesome-free/scss/solid";
// import "@fortawesome/fontawesome-free/scss/regular";
import "./application.scss";



// Images
const images = require.context("../../assets/images", true);
const imagePath = (name) => images(name, true);

  
class OktaTokenManager {
  static state = window?.ReelMetrics?.token_manager ?? {
    expires: Date.now() + 1000 * 60 * 60,
  };

  static expire_buffer_minutes = 10;

  static init() {
    setTimeout(this.refreshToken, this.timeOutInterval());
    return this.setBiServerBeforeSend();
  }

  static setBiServerBeforeSend() {
    return $.ajaxSetup({
      beforeSend(xhr, settings) {
        if (settings.data && settings.data.indexOf("report_query") > -1) {
          const savedReportUrl = $(window)[0].location.href.split(/[/|?]/);
          settings.type = "POST";
          settings.contentType = "application/json";
          return (settings.url =
            savedReportUrl[0] + "//" + savedReportUrl[2] + "/do_query");
        } else if (settings.url.indexOf("pentaho") > -1) {
          return xhr.setRequestHeader(
            "Authorization",
            `bearer ${OktaTokenManager.getAccessToken()}`
          );
        }
      },
    });
  }

  static timeOutInterval() {
    const expireBuffer = this.expire_buffer_minutes * 1000 * 60;
    let calculatedTimeout = expireBuffer;

    if (this.state && this.state.expires) {
      calculatedTimeout =
        new Date(`${this.state.expires}`) - Date.now() - expireBuffer;
    }
    if (calculatedTimeout > 600000) {
      return calculatedTimeout;
    } else {
      return expireBuffer;
    }
  }

  static getAccessToken() {
    return this.getCookie("rm_access_token");
  }

  static getCookie(c_name) {
    if (document.cookie.length <= 0) {
      return "";
    }
    const match = new RegExp(`(${c_name}=)([^;]*)([;]{0,1})`).exec(
      document.cookie
    );
    if (match == null) {
      return "";
    }
    return unescape(match[2]);
  }

  static refreshToken() {
    $.ajaxSetup({ async: true });
    return $.ajax({
      method: "POST",
      url: "/session/refresh_access_token",
      dataType: "json",
      success(payload) {
        console.log("= Refreshed the OIDC Access Token token =");
        console.log(
          `The new access-token will expire at: ${new Date(
            payload["expires"]
          ).toString()}`
        );
        console.log(`Current Date/Time: ${new Date().toString()}`);
        OktaTokenManager.setBiServerBeforeSend();
        OktaTokenManager.state.expires = new Date(
          payload["expires"]
        ).toString();
        return setTimeout(
          OktaTokenManager.refreshToken,
          OktaTokenManager.timeOutInterval()
        );
      },
    });
  }
}

export default OktaTokenManager;

const formatVideoKeys = () => {
  if (
    !$("#video_key").val() &&
    $("#video_title").val() &&
    $("#video_title").val().length > 0
  ) {
    return $("#video_key").val(
      $("#video_title")
        .val()
        .toUpperCase()
        .replace(/[^0-9_ A-Z]/g, "")
        .replace(/\s+/g, "_")
    );
  }
};

$(window).on("load", () => {
  $("#video_title").on("blur", formatVideoKeys);
});
